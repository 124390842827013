.modal__background {
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .modal__content {
    z-index: 2000;
    margin: 14rem auto;
    width: 35rem;
    padding: 1rem;
    background-color: #f8f8f8;
    border-radius: 5px;
    box-shadow: 0 0 0.3rem 0.3rem rgba(255, 255, 255, 0.4);
  }

  .WorkExperiencesCardsContainer{
    margin: 2em 0 2em 0;
}

.WorkModalCards{
    display:flex;
    flex-wrap: wrap;
    gap: 2em;
    align-items: flex-start;
    justify-content: center;
    margin: 0 3em;
}

/* Tablet */
@media (max-width: 820px) {
    .LandingContainer{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
}

/* Phone */
@media (max-width: 414px) {
    .WorkModalCards{
        margin: 0;
        gap: 2em 0;
        
    }
}