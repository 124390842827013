.LandingLeftContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 30px 20px 30px;

    width: 80%;
    min-width: 350px;
    max-width: 400px;
    color: #013237;
}

.LandingLeftContainer h1{
    color:#013237;
    margin: 5px 5px 10px 5px;
    font-family: Montserrat;
    font-weight: 700;
    font-size: 3em;
}

.LandingLeftContainer h2{
    font-size: 20px;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: 100;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.5em;
    gap: 0 10px;
}

.LandingButton{
    background-color: #F7B318;
    padding: 30px 60px 30px 60px;
    border: none;
    border-radius: 1em;
    margin: 32px 0px 32px 0px;
    text-decoration: none;
    
    font-size: 20px;
    font-family: Montserrat;
    color: #013237;
}

.LandingButton:hover{
    background-color:#C8E0CA;
    color: #013237
}

.LandingButton:active{
        box-shadow: 0px 0px 20px 1px #C8E0CA;
}

/* Tablet */
@media (max-width: 820px) {
    .LandingLeftContainer{
        margin: 20px 0;
    }
    
    .LandingLeftContainer h1{
        font-size: 30px;
    }
    .LandingLeftContainer h2{
        font-size: 16px;
    }
    .LandingButton{
        font-size: 16px;
    }

}

/* Phone */
@media (max-width: 414px) {
    .LandingLeftContainer h1{
        font-size: 20px;
    }
    .LandingLeftContainer h2{
        font-size: 14px;
    }
    .LandingButton{
        font-size: 14px;
        padding: 30px;
    }

} 